body {
  /* font-family: 'Jost' !important; */
  color: #6a0b0b;
}

h1 {
  font-family: 'Outfit' !important;
}

.authed-layout {
  font-size: 0.9rem !important;

  main {
    padding: 57px;
  }

  .btn {
    font-size: 0.9rem !important;
  }
} 

.mobile-nav {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
}

.collapse:not(.show) {
  transition: visibility 1s ease-in-out;
}

.mobile-nav .collapse {
  background-color: rgba(255, 255, 255, 0.9);
  color: #6a0b0b;
  display: flex !important;
  position:absolute;
  width: 100%;
  top: 71px;
  padding-bottom: 30px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: visibility 1s ease-in-out;
}

.mobile-nav .navbar-nav {
  width: 100%;
}

.mobile-nav .nav-item {
  display:flex;
  margin-top: 1em;
}

.navbar {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.1s ease;
  backdrop-filter: blur(10px);
  /* z-index: 1000; */
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.9);
}

.nav-item {
  position: relative;
  z-index: 1;
}

.navbar .nav-link, .navbar .nav-item.active .nav-link {
  color: #fff;
}

.mobile-nav .nav-link, .navbar.scrolled .nav-link {
  color: #6a0b0b !important;
  /* background: #ff4747; */
}

.mobile-nav .nav-item::before {
  display: none;
}

.nav-item::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) skewX(0deg);
  width: 100%;
  height: 0px;
  background-color: rgba(186, 77, 77, 0.8);
  transition: height 0.5s ease, transform 0.5s ease;
  
}

.nav-item:hover::before {
  z-index: -1;
  height: 90px;
  transform: translateX(-50%) translateY(-25px) skewY(-8deg);
  z-index: -1;
}

.nav-item:hover .nav-link {
  color: #fff !important;
}

.navbar-nav .nav-item.active:not(:first-child)  {
  border-bottom: 2px solid rgb(186, 77, 77);
  
}

.slide-text {
  /* font-family: 'Outfit' !important; */
  text-shadow: 1px 1px 2px black, 0 0 5px red, 0 0 5px darkred;
  top: 50%;
  font-size: 4em;
  font-weight: bold;
  line-height: 1.2;
  max-width: 50%;
  /* transform: translateX(100%);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out; */
  z-index: 1000;
}

@media (max-width: 576px) {
  .slide-text {
    font-size: 2.5rem;
    max-width: 80%; 
  }
}


.sticky-col {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  left: 0;
  background-color: white; /* Ensure the background is not transparent */
  z-index: 2; /* Ensure the sticky columns appear on top */
}

.first-col {
  left: 0;
  padding-left: 1rem !important;
}

.second-col {
  left: 30px; /* Adjust according to the width of the first column */
}

@media (max-width: 768px) {
  .offcanvas-end {
    width: 100% !important;
    max-width: 100% !important;
  }
}
